import axios from 'axios';

// function objectToUrlParams(obj) {
//     const params = new URLSearchParams();
//     for (const key in obj) {
//         if (Object.hasOwnProperty.call(obj, key)) {
//             params.append(key, obj[key]);
//         }
//     }
//     return params.toString();
// }

const ApiService = {
    // Fonction générique pour effectuer une requête
    makeRequest: async (config) => {
        const baseUrl = "https://api.cryptoinitiate.fr";

        try {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                ...(config.token && { 'Authorization': `Bearer ${config.token}` }),
                ...(config.token_user && { 'Authorization-User': `${config.token_user}` }),
            };

            const axiosConfig = {
                method: config.method,
                url: baseUrl + config.url,
                headers,
                ...(config.method === 'post' && { data: config.data }),
            };

            const response = await axios(axiosConfig);
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la requête API', error);
            throw error;
        }
    },

    // Fonction pour effectuer une requête GET
    get: async (url, data = null, token = null, token_user = null) => {
        return ApiService.makeRequest({ method: 'get', url, data, token, token_user });
    },

    // Fonction pour effectuer une requête POST
    post: async (url, data, token = null, token_user = null) => {
        return ApiService.makeRequest({ method: 'post', url, data, token, token_user });
    },

    // Fonction pour effectuer une requête PUT
    put: async (url, token = null, token_user = null) => {
        // const urlParams = url+'?'+objectToUrlParams(data);
        // return ApiService.makeRequest({ method: 'put', url: urlParams, token });
        return ApiService.makeRequest({ method: 'put', url, token, token_user});
    },

    // Fonction pour effectuer une requête DELETE
    delete: async (url, data, token = null, token_user = null) => {
        return ApiService.makeRequest({ method: 'delete', url, data, token, token_user });
    },
};

export default ApiService;