import './css/loader.css';
import React, { lazy, Suspense } from 'react';
import Loading from './components/Layouts/Loading';
import Security from './components/Server/Security';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );

      componentImport().then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) {
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
              return window.location.reload();
          }
          reject(error);
      });
  });
};

const Dashboard = lazy(() => lazyRetry(() => import('./components/Pages/Dashboard/Index')));
const Login = lazy(() => lazyRetry(() => import('./components/Pages/Login/Index')));
// const Logout = lazy(() => lazyRetry(() => import('./components/Pages/Logout')));
const Users = lazy(() => lazyRetry(() => import('./components/Pages/Users/Index')));
const Accompaniments = lazy(() => lazyRetry(() => import('./components/Pages/Accompaniments/Index')));
const Academy = lazy(() => lazyRetry(() => import('./components/Pages/Academy/Index')));
const Contacts = lazy(() => lazyRetry(() => import('./components/Pages/Contacts/Index')));
const Access = lazy(() => lazyRetry(() => import('./components/Pages/Access/Index')));
const LinksCenter = lazy(() => lazyRetry(() => import('./components/Pages/LinksCenter/Index')));

const Cookies = lazy(() => lazyRetry(() => import('./components/Layouts/Cookies')));

const Err404 = lazy(() => lazyRetry(() => import('./components/Pages/Error/Err404')));

class RouterApp extends React.Component {
  render() {
    return (
        <Router>
          <Suspense fallback={
              <Loading />
          }>
            <Routes>
              {/* NO AUTHENTICATION REQUIRED START */}
                <Route path="/" element={<Login showNavbar={false} />} />
                <Route path="/login" element={<Login showNavbar={false} />} /> 
              {/* NO AUTHENTICATION REQUIRED END */}

              {/* AUTHENTICATION REQUIRED START */}
                <Route path="/dashboard" element={
                  <Security>
                    <Dashboard />
                  </Security>
                } />
                <Route path="/users" element={
                  <Security>
                    <Users />
                  </Security>
                } />
                <Route path="/accompaniments" element={
                  <Security>
                    <Accompaniments />
                  </Security>
                } />
                <Route path="/academy" element={
                  <Security>
                    <Academy />
                  </Security>
                } />
                <Route path="/contacts" element={
                  <Security>
                    <Contacts />
                  </Security>
                } />
                <Route path="/access" element={
                  <Security>
                    <Access />
                  </Security>
                } />
                <Route path="/links-center" element={
                  <Security>
                    <LinksCenter />
                  </Security>
                } />
                {/*
                  <Route path="/users" element={<Users showNavbar={true} />} />
                  <Route path="/accompaniment" element={<Accompaniment showNavbar={true} />} />
                  <Route path="/academy" element={<Academy showNavbar={true} />} />
                  <Route path="/contacts" element={<Contacts showNavbar={true} />} />
                  <Route path="/roles" element={<Roles showNavbar={true} />} />
                  <Route path="/logout" element={<Logout />} />
                */}
              {/* AUTHENTICATION REQUIRED END */}

              {/* ERROR PAGE START */}
              <Route path="*" element={<Err404 showNavbar={false} />} />
              {/* ERROR PAGE END */}
            </Routes>
          </Suspense>
          <Cookies />
        </Router>
    );
  }
}

export default RouterApp;